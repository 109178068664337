body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: black
}

#root {
  min-height: calc(
    var(--vh, 1vh) * 100
  ); /* Use vh as a fallback for browsers that do not support Custom Properties */
  display: flex;
}

.p-right {
  padding-right: 4px;
}

.p-left {
  padding-right: 4px;

}

@font-face {
  font-family: "Futura-Bold";
  src: local("Futura-Bold"), url(fonts/futura-extrabold.otf) format("otf");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.scrollBar::-webkit-scrollbar {
  display: none;
  background: red;
}
